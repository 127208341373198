<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="success ">
                <v-toolbar-title class="mx-auto">{{
                  $t("LoginCustomer")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <div>
                  <v-img
                    class="mx-auto"
                    style="width: 150px; height: 150px"
                    src="/assets/logo.jpeg"
                  ></v-img>
                </div>

                <v-form>

                  <div
                    class="py-0"
                    :class="{
                      'has-error': errors.has('addEditValidation.mobile'),
                    }"
                  >
                    <h4 class="mb-2 mt-5">
                      {{ $t("Mobile") }} <span class="required">*</span>
                    </h4>
                    <v-text-field
                      dense
                      class="mb-2"
                      outlined
                      name="mobile"
                      type="text"
                      v-validate="'required'"
                      data-vv-scope="addEditValidation"
                      :data-vv-as="$t('Mobile')"
                      v-model="object.mobile"
                      hide-details
                    ></v-text-field>
                    <div
                      class="help-block"
                      v-if="errors.has('addEditValidation.mobile')"
                    >
                      {{ errors.first("addEditValidation.mobile") }}
                    </div>
                  </div>

                  <div
                    class="py-0"
                    :class="{
                      'has-error': errors.has('addEditValidation.password'),
                    }"
                  >
                    <h4 class="mb-2 mt-2">
                      {{ $t("Password") }} <span class="required">*</span>
                    </h4>
                    <v-text-field
                      dense
                      class="mb-2"
                      outlined
                      name="password"
                      type="password"
                      v-validate="'required'"
                      data-vv-scope="addEditValidation"
                      :data-vv-as="$t('Password')"
                      v-model="object.password"
                      hide-details
                      @keypress.enter.prevent="login"
                    ></v-text-field>
                    <div
                      class="help-block"
                      v-if="errors.has('addEditValidation.password')"
                    >
                      {{ errors.first("addEditValidation.password") }}
                    </div>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions class="py-0 d-flex justify-center">
                <v-btn
                  class="mb-3"
                  small
                  outlined
                  width="160"
                  color="info"
                  @click="login"
                  >{{ $t("Login") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    type: 1,
    typeList: [],
    object: {
      mobile: null,
      password: null,
    },
  }),
  methods: {
    setActive(type) {
      this.type = type;
    },
    login() {
      localStorage.setItem('customerMobileFirstLogin', this.object.mobile);
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (valid) {
          if (this.type == 1) {
            this.$store.dispatch("auth/loginCustomer", this.object).then((result) => {
              this.notifySuccess("Login", result);
              window.location.href = "/customer/home";
              this.loginCustomerSuccess(result);
            })
          } 
        } else {
          let result = {
            response: {
              data: {
                errors: this.$t("MissedData"),
              },
            },
          };
          this.notifyError("Login", result);
        }
      });
    },
  },
  mounted() {
    this.typeList = [
      {
        id: 1,
        name: this.$t("TypeUser"),
      },
      {
        id: 2,
        name: this.$t("TypeDriver"),
      },
    ];
  },
};
</script>


<style>
.btn-gradient-1 {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  margin: 0;
  background: #fff;
  color: #000;
  border: 1px solid #000 !important;
}

svg path {
  fill: #000 !important;
}

.active-login {
  background: linear-gradient(to right, #4b6cb7, #182848);
  color: white;
}

.active-login svg path {
  fill: #fff !important;
}

.active-login .fa-check {
  display: inline-block;
}

.fa-check {
  display: none;
}
</style>